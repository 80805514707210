import { FC, PropsWithChildren } from 'react';
import { Box, Dialog } from '@mui/material';
import verifyImage from '../../../../shared/assets/img/verify-image.jpg';

type Props = {
  open: boolean;
  handleClose: () => void;
};
export const VerifyImageInfoDialog: FC<PropsWithChildren<Props>> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      transitionDuration={1000}
      aria-labelledby="alert-dialog-image"
      aria-describedby="alert-dialog-info"
    >
      <Box component="img" src={verifyImage} alt="Let's String" onClick={handleClose}></Box>
    </Dialog>
  );
};
