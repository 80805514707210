import { Route } from '../../../shared/router';
import { CouponsItemPage, CouponsPage } from '../pages';

export const routes: Route[] = [
  {
    name: 'coupons',
    title: 'Coupons',
    path: '/admin/coupons',
    component: CouponsPage,
    layoutType: 'adminPrivate',
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'admin',
  },
  {
    name: 'coupons',
    title: 'Coupon Item',
    path: '/admin/coupons/:code',
    component: CouponsItemPage,
    layoutType: 'adminPrivate',
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'admin',
  },
];
