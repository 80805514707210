import { Paper, TableContainer } from '@mui/material';
import { FC } from 'react';
import { TableView } from 'shared/components';
import { formatDateTime } from 'shared/tools';
import { CouponsState } from '../slices';
import { CouponFilter, CouponsData } from '../types';
import { NavLink } from 'react-router-dom';

type Props = {
  loading: Record<keyof CouponsState['loading'], boolean>;
  couponList: CouponsData;
  filter: CouponFilter;
  changeSort: (sort: string) => Promise<void>;
  changePage: (pageNumber: number) => Promise<void>;
  changePageSize: (pageSize: number) => Promise<void>;
};

export const CouponsTableView: FC<Props> = ({
  loading,
  couponList,
  filter,
  changeSort,
  changePage,
  changePageSize,
}) => {
  return (
    <TableContainer component={Paper}>
      <TableView
        isLoading={loading.getCoupons || loading.generateCoupons}
        records={couponList.items}
        notFoundText="There is no coupon here"
        sort={filter.orderedby}
        onChangeSort={changeSort}
        columns={[
          {
            title: 'Code',
            cell: r => {
              return <NavLink to={'coupons/' + r.code}>{r.code}</NavLink>;
            },
            sort: 'code',
          },
          {
            title: 'Status',
            cell: r => {
              return r.statusId;
            },
            sort: 'statusId',
          },
          {
            title: 'Create Date',
            cell: r => formatDateTime(r.createdAt),
            sort: 'id',
          },
          {
            title: 'Using',
            cell: r => {
              return r.usingCount;
            },
            sort: 'usingCount',
          },
        ]}
        page={{
          pagination: {
            page: filter.pageNumber ?? 0,
            size: filter.pageSize,
            total: couponList.totalCount,
          },
          pageSizes: [15, 50, 100, 500, 1000],
          onChangePage: changePage,
          onChangePageSize: changePageSize,
        }}
      />
    </TableContainer>
  );
};
