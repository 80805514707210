import { Box, Dialog, Grid, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useLangContext } from 'shared/features/Language';
import { ButtonWithIcon } from '../Buttons';
import { CustomIcon } from 'shared/assets/icons';

type Props = {
  open: boolean;
  handleClose: () => void;
};
export const ModalCountryConfirmDialog: FC<PropsWithChildren<Props>> = ({ open, handleClose }) => {
  const { textData } = useLangContext();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          width: { md: '525px', xs: '310px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" sx={{ fontSize: '20px', padding: '15px 0' }}>
          {textData.chooseCountry}
        </Typography>
        <Grid
          container
          sx={{
            width: { md: '60%', xs: '100%' },
            padding: '20px',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <Grid item flex="1 1" onClick={handleClose}>
            <Box component="a" href="https://letsstring.pl/" target="_blank">
              <ButtonWithIcon
                icon={
                  <CustomIcon
                    name="arrowTopBlack"
                    styles={{ '& > svg': { width: '28px', height: '28px' } }}
                  />
                }
                color="light"
                position="end"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: '18px', lineHeight: '27px', paddingLeft: '15px' }}
                >
                  {textData.buttonPoland}
                </Typography>
              </ButtonWithIcon>
            </Box>
          </Grid>

          <Grid item flex="1 1" onClick={handleClose}>
            <Box component="a" href="https://letsstring.store/" target="_blank">
              <ButtonWithIcon
                icon={
                  <CustomIcon
                    name="arrowTopBlack"
                    styles={{ '& > svg': { width: '28px', height: '28px' } }}
                  />
                }
                color="light"
                position="end"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: '18px', lineHeight: '27px', paddingLeft: '15px' }}
                >
                  {textData.buttonOtherCountries}
                </Typography>
              </ButtonWithIcon>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
