import { HeaderView, LinkButton, Loading, LoadingContainer } from '../../../shared/components';
import { Container, Typography } from '@mui/material';
import { CouponsItemTableView } from './CouponsItemTableView';
import { useParams } from 'react-router';
import { useCallback, useState } from 'react';
import { CouponData } from '../types';
import { useAdminCouponsApi } from '../api';
import { useEffectOnce } from 'react-use';
import { Box } from '@mui/system';

export const CouponsItemView = () => {
  const { code } = useParams<{ code: string }>();
  const [coupon, setCoupon] = useState<CouponData>(null);
  const title = `Coupon ${code ?? ''}`;
  const couponsApi = useAdminCouponsApi();
  const getCoupon = useCallback(async () => await couponsApi.getCoupon(code), [couponsApi]);

  useEffectOnce(() => {
    getCoupon().then(response => setCoupon(response));
  });

  return (
    <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
      <Box sx={{ marginBottom: '40px' }}>
        <LinkButton to={'/admin/coupons'}>
          <Typography
            variant="overline"
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            Back
          </Typography>
        </LinkButton>
      </Box>
      <HeaderView title={title} />
      {coupon ? (
        <CouponsItemTableView usings={coupon.usings} />
      ) : (
        <LoadingContainer flex="1 1">
          <Loading />
        </LoadingContainer>
      )}
    </Container>
  );
};
