import { useMemo } from 'react';
import { useApiAdminClient, useApiUrlBuilder } from 'shared/tools';
import { CouponData, CouponsData } from '../types';

export function useAdminCouponsApi() {
  const api = useApiAdminClient();
  const apiUrlBuilder = useApiUrlBuilder();

  return useMemo(
    () => ({
      getCoupon: (id: string) =>
        api.get<CouponData>({
          path: apiUrlBuilder.adminCoupon(id),
        }),
      getCoupons: ({
        pageNumber,
        pageSize,
        filter,
        orderby,
      }: {
        pageNumber: number;
        pageSize: number;
        orderby?: string;
        filter?: string;
      }) =>
        api.get<CouponsData>({
          path: apiUrlBuilder.adminCoupons({
            skip: pageNumber * pageSize,
            top: pageSize,
            filter,
            orderby,
          }),
        }),

      generateCoupons: (count: number) =>
        api.post({
          path: apiUrlBuilder.adminGenerateCoupons(count),
        }),
    }),
    [api, apiUrlBuilder],
  );
}
