import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import React, { FC } from 'react';
import { FormComponentProps, FormTextBox } from 'shared/components';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from 'shared/tools';
import { LoginFormData } from '../../types';

import './style.css';

export const LoginForm: FC<FormComponentProps<LoginFormData>> = props => {
  const { form } = props;

  return (
    <Card>
      <CardHeader title="Sign in" />

      <CardContent>
        <Box mt={1} className={'login-form'}>
          <FormTextBox
            form={form}
            label="Email"
            fieldName="username"
            validation={{
              required: 'Email address is required',
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Email address has invalid format',
              },
            }}
          />

          <FormTextBox
            form={form}
            label="Password"
            fieldName="password"
            validation={{
              required: 'Password is required',
              pattern: {
                value: PASSWORD_PATTERN,
                message: 'Password must contain at least 4 characters',
              },
            }}
            type="password"
          />
        </Box>

        <Box mt={3}>
          <Button
            variant="contained"
            type="submit"
            disabled={form.formState.isSubmitting}
            fullWidth
            size="large"
            sx={{
              backgroundColor: '#272727',

              '&:hover': {
                backgroundColor: 'white',
                color: '#272727',
              },
            }}
          >
            Continue
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
